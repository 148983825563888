<template>
	<v-container fluid>
		<please-select-utility>
			<v-row>
				<v-col cols="12" class="d-flex">
					<session-select
						:utility="selectedUtility"
						v-model="selectedSession"
						:session.sync="sessionData"
						dense outlined hide-details single-line label="Сесия"
						style="max-width: 400px"
						class="mr-2"
						clearable
					/>
					<station-select-all
						:utility="selectedUtility"
						:session="selectedSession"
						v-model="selectedStation"
						clearable
						dense outlined hide-details single-line label="Станция"
						style="max-width: 600px"
						class="mr-2"
					/>
					<v-select clearable dense outlined hide-details single-line
						class="mr-2"
						label="Отчетен период"
						v-model="mesechen"
						:items="[
							{value:0,text:'Годишен'},
							{value:1,text:'Месечен'},
						]"
					/>
					<v-spacer/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<search @filter="filter = $event" :imodel="imodel" />
					<list
						v-if="session"
						ref="dataTable"
						hide-edit hide-select hide-delete
						:imodel="imodel"
						:filter="filter"
						:params="{
							utility: selectedUtility,
							session: selectedSession,
							station: selectedStation,
							mesechen,
						}"
						item-key="n_imot_data.id"
						:headers="headers.join(',')"
						hidden-cols="n_imot_data.id,n_imot_data.imot_id,n_imot_data.station_id,n_imot_data.session_id"
					>
						<template #right-actions="{item}">
							<imot-details-link
								target-blank
								:utility="utility"
								:session="session"
								:station="item['n_imot_data.station_id']"
								:imot="item['n_imot_data.imot_id']"
							/>
						</template>
					</list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import List from "@/ittijs/List";
import Model from "@/views/imot_data/ModelJoin";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SessionSelect from "@/views/sessions/SessionSelect";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import Search from "@/ittijs/Search";
import StationSelectAll from "@/views/stations/StationSelectAll";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";

const model = new Model;

export default {
	components: {
		ImotDetailsLink,
		StationSelectAll,
		Search,
		PleaseSelectUtility,
		SessionSelect,
		List,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'selectedSession',
		}),
	],
	props: [
		'utility',
		'session',
		'station',
	],
	data(){
		return {
			selectedSession: null,
			selectedStation: null,
			mesechen: null,
			sessionData: null,
			imodel: model,
			filter: {},
		}
	},
	computed: {
		isIzravnitelna(){
			return this.sessionData && this.sessionData.izr_sess != '0';
		},
		headers(){
			return [
				'_sd.station_N',
				'n_imot_data.imot_N',
				'n_imot_data.address',
				'n_imot_data.volume',
				'brl',
				'n_imot_data.prognozen_dial_heat',
				'n_imot_data.prognozen_dial_sgri',
				'n_imot_data.e_hot_water',
				'n_imot_data.e_sgri',
				'n_imot_data.e_heat',
				...(this.isIzravnitelna ? [
					'n_imot_data.izr_amount_invoiced',
					'n_imot_data.izr_amount_razpr',
					'izr_amount_total',
				] : [
					'suma',
				]),
				'_sd.mesechen',
			];
		},
	},
}
</script>