var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('please-select-utility',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('session-select',{staticClass:"mr-2",staticStyle:{"max-width":"400px"},attrs:{"utility":_vm.selectedUtility,"session":_vm.sessionData,"dense":"","outlined":"","hide-details":"","single-line":"","label":"Сесия","clearable":""},on:{"update:session":function($event){_vm.sessionData=$event}},model:{value:(_vm.selectedSession),callback:function ($$v) {_vm.selectedSession=$$v},expression:"selectedSession"}}),_c('station-select-all',{staticClass:"mr-2",staticStyle:{"max-width":"600px"},attrs:{"utility":_vm.selectedUtility,"session":_vm.selectedSession,"clearable":"","dense":"","outlined":"","hide-details":"","single-line":"","label":"Станция"},model:{value:(_vm.selectedStation),callback:function ($$v) {_vm.selectedStation=$$v},expression:"selectedStation"}}),_c('v-select',{staticClass:"mr-2",attrs:{"clearable":"","dense":"","outlined":"","hide-details":"","single-line":"","label":"Отчетен период","items":[
						{value:0,text:'Годишен'},
						{value:1,text:'Месечен'},
					]},model:{value:(_vm.mesechen),callback:function ($$v) {_vm.mesechen=$$v},expression:"mesechen"}}),_c('v-spacer')],1)],1),_c('v-row',[_c('v-col',[_c('search',{attrs:{"imodel":_vm.imodel},on:{"filter":function($event){_vm.filter = $event}}}),(_vm.session)?_c('list',{ref:"dataTable",attrs:{"hide-edit":"","hide-select":"","hide-delete":"","imodel":_vm.imodel,"filter":_vm.filter,"params":{
						utility: _vm.selectedUtility,
						session: _vm.selectedSession,
						station: _vm.selectedStation,
						mesechen: _vm.mesechen,
					},"item-key":"n_imot_data.id","headers":_vm.headers.join(','),"hidden-cols":"n_imot_data.id,n_imot_data.imot_id,n_imot_data.station_id,n_imot_data.session_id"},scopedSlots:_vm._u([{key:"right-actions",fn:function({item}){return [_c('imot-details-link',{attrs:{"target-blank":"","utility":_vm.utility,"session":_vm.session,"station":item['n_imot_data.station_id'],"imot":item['n_imot_data.imot_id']}})]}}],null,false,245820368)}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }